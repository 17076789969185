export const transformToNumber = (val: unknown) => {
  if (typeof val === "number") return val;
  return typeof val === "string" ? Number(val) : undefined;
};

export const transformToData = (val: unknown) =>
  val && typeof val === "string" ? new Date(val) : undefined;

export const filterObjectValues = (val: unknown = (val: unknown) => val) => {
  if (val && typeof val === "object") {
    return Object.entries(val)
      .filter(([, value]) => !!value)
      .reduce(
        (a, [key, value]) => ({
          ...a,
          [key]: value,
        }),
        {},
      );
  }
  return;
};
