import { apiClient } from "@/services/http.ts";
import { useMutation, useQuery } from "@tanstack/react-query";
import qs from "qs";
import { z } from "zod";
import { pageSchema, ParamsType } from "@/services/common.ts";

export const FlowSchema = z.object({
  id: z.string().uuid(),
  createdAt: z.preprocess(
    (val) => (val && typeof val === "string" ? new Date(val) : undefined),
    z.date(),
  ),
  updatedAt: z.preprocess(
    (val) => (val && typeof val === "string" ? new Date(val) : undefined),
    z.date(),
  ),
  type: z.string(),
  status: z.string(),
  sequenceId: z.string().uuid().nullable(),
  errorMessage: z.string().nullable(),
  user: z.object({
    id: z.string(),
    name: z.string(),
  }),
  logs: z.array(
    z.object({
      level: z.string(),
      createdAt: z.preprocess(
        (val) => (val && typeof val === "string" ? new Date(val) : val),
        z.date(),
      ),
      message: z.string(),
    }),
  ),
});

export type FlowType = z.infer<typeof FlowSchema>;

const getFlows = async (
  { paginationModel, filterModel, sortModel }: ParamsType,
  signal: AbortSignal,
) => {
  const response = await apiClient.post("/flows/search", filterModel, {
    params: {
      page: paginationModel.page,
      size: paginationModel.pageSize,
      sort: sortModel?.map(
        (sort) => `${sort.field.replace(".id", ".name")},${sort.sort}`,
      ),
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
    signal,
  });
  return pageSchema(FlowSchema.omit({ logs: true })).parse(response.data);
};

export const useFlows = (params: ParamsType) =>
  useQuery({
    queryKey: ["flows", params],
    queryFn: ({ signal }) => getFlows(params, signal),
  });

export const ExpenseGenerationFlowFormSchema = z.object({
  employee: z
    .object({
      id: z.string(),
      name: z.string(),
      startDate: z.string(),
      totalKms: z.number(),
    })
    .nullable(),
  monthYear: z.date().nullable(),
  userExtraOnFirstMonth: z.boolean(),
});

export type ExpenseGenerationFlowFormType = z.infer<
  typeof ExpenseGenerationFlowFormSchema
>;

export const ExpenseGenerationFlowRequestSchema = z.object({
  employee: z.preprocess(
    (val) => val && typeof val === "object" && "id" in val && val.id,
    z.string().uuid(),
  ),
  monthYear: z.date(),
  userExtraOnFirstMonth: z.boolean(),
});

export type ExpenseGenerationFlowRequestType = z.infer<
  typeof ExpenseGenerationFlowRequestSchema
>;

const createExpenseGenerationFlow = async (
  data: ExpenseGenerationFlowRequestType,
) => {
  const response = await apiClient.post<string>(
    "/flows/expense-generation",
    data,
  );
  return response.data;
};

export const useCreateExpenseGeneratorFlow = () =>
  useMutation({
    mutationKey: ["flow-expense-generator"],
    mutationFn: createExpenseGenerationFlow,
  });

export const getFlow = async (id: string, signal: AbortSignal) => {
  const response = await apiClient.get(`/flows/${id}`, { signal });
  return FlowSchema.parse(response.data);
};

export const useFlowData = (
  initialData: FlowType,
  id: string,
  enabled: boolean,
) =>
  useQuery({
    queryFn: ({ signal }) => getFlow(id, signal),
    queryKey: ["flows", id],
    refetchInterval: 1500,
    refetchIntervalInBackground: true,
    enabled,
    initialData,
  });

export type MovementGenerationFlowFormType = {
  allEmployees: boolean;
  employees: string[];
  monthYear: Date | null;
  date: [Date | null, Date | null];
};

export const MovementGenerationFlowRequestSchema = z.object({
  allEmployees: z.boolean(),
  employees: z.preprocess(
    (val) => val && Array.isArray(val) && val.map((v) => v.id),
    z.array(z.string().uuid()),
  ),
  monthYear: z.date(),
  date: z.tuple([z.date(), z.date()]),
});

export type MovementGenerationFlowRequestType = z.infer<
  typeof MovementGenerationFlowRequestSchema
>;

const createMovementGenerationFlow = async (
  data: MovementGenerationFlowRequestType,
) => {
  const response = await apiClient.post<string>(
    "/flows/movement-generation",
    data,
  );
  return response.data;
};

export const useCreateMovementGeneratorFlow = () =>
  useMutation({
    mutationKey: ["movement-expense-generator"],
    mutationFn: createMovementGenerationFlow,
  });

const retryFlow = async (flowId: string) => {
  const response = await apiClient.get<string>(`/flows/${flowId}/retry`);
  return response.data;
};

export const useRetryFlow = () =>
  useMutation({
    mutationKey: ["retry-flow"],
    mutationFn: retryFlow,
  });

export const DocumentSendingFlowRequestSchema = z.object({
  allEmployees: z.boolean(),
  employeeIds: z.preprocess(
    (val) => val && Array.isArray(val) && val.map((v) => v.id),
    z.array(z.string().uuid()),
  ),
  monthYear: z.date(),
});

export type DocumentSendingFlowRequestType = z.infer<
  typeof DocumentSendingFlowRequestSchema
>;

const createDocumentSendingFlow = async (
  data: DocumentSendingFlowRequestType,
) => {
  const response = await apiClient.post<string>(
    "/flows/document-sending",
    data,
  );
  return response.data;
};

export const useCreateDocumentSendingFlow = () =>
  useMutation({
    mutationKey: ["document-sending-generator"],
    mutationFn: createDocumentSendingFlow,
  });
