import { Suspense } from "react";

import { PrimeAuth } from "@primeit/react-auth";
import { ErrorBoundary, lightTheme } from "@primeit/react-core";
import { AuthLoader, ErrorPage, UnauthorizedPage } from "@primeit/react-pages";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import { QueryClientProvider } from "@tanstack/react-query";
import { pt } from "date-fns/locale/pt";

import { queryClient } from "@/query-client";
import AppRouter from "@/routes";
import { RecoilRoot } from "recoil";
import { SnackbarProvider } from "notistack";
import BackdropLoader from "@/components/backdrop-loader.tsx";

LicenseInfo.setLicenseKey(
  "05cee51a740b2f78636f4d0e8084fedaTz05NzUxNyxFPTE3NTcwNzI0ODMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==",
);

const Loader = () => {
  return <AuthLoader />;
};

const Unauthorized = () => {
  return <UnauthorizedPage />;
};

function App() {
  return (
    <RecoilRoot>
      <ThemeProvider theme={lightTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pt}>
          <CssBaseline />
          <ErrorBoundary
            fallback={<ErrorPage message={"Algo aconteceu por aqui!"} />}
          >
            <SnackbarProvider maxSnack={10}>
              <Suspense fallback={<BackdropLoader />}>
                <PrimeAuth
                  ssoUrl={import.meta.env.VITE_SSO_URL}
                  clientId={import.meta.env.VITE_SSO_CLIENT_ID}
                  audience={import.meta.env.VITE_SSO_AUDIENCE}
                  redirectUri={window.location.origin}
                  unauthorizedPage={Unauthorized}
                  loadingPage={Loader}
                >
                  <QueryClientProvider client={queryClient}>
                    <AppRouter />
                  </QueryClientProvider>
                </PrimeAuth>
              </Suspense>
            </SnackbarProvider>
          </ErrorBoundary>
        </LocalizationProvider>
      </ThemeProvider>
    </RecoilRoot>
  );
}

export default App;
