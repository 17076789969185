import { apiClient } from "./http";
import { z } from "zod";
import { useMutation } from "@tanstack/react-query";
import { useRouteLoaderData } from "react-router-dom";
import { MyCompanyType } from "@/services/companies.ts";

export const GeneralSettingsSchema = z.object({
  businessDays: z.string().array(),
});

export type GeneralSettingsType = z.infer<typeof GeneralSettingsSchema>;

export const getGeneralSettings = async (signal: AbortSignal) => {
  const response = await apiClient.get("/settings/general", { signal });
  return GeneralSettingsSchema.parse(response.data);
};

export const GeneralSettingsFormSchema = z.object({
  businessDays: z.string().array(),
});

export type GeneralSettingsPreForm = {
  businessDays: string[];
};

export type GeneralSettingsFormType = z.infer<typeof GeneralSettingsFormSchema>;

const updateGeneralSettings = async (data: GeneralSettingsType) => {
  const response = await apiClient.put("/settings/general", data);
  return response.data;
};

export const useUpdateGeneralSettings = () =>
  useMutation({
    mutationKey: ["general_settings"],
    mutationFn: updateGeneralSettings,
  });

export const IntegrationSettingsSchema = z.object({
  googleMapsApiKey: z.string().min(30).nullable(),
  komiDocConfig: z.object({
    token: z.string().min(30).nullable(),
    host: z.string().url(),
    idParentFolder: z.string(),
    categoryFilingId: z.string(),
  }),
});

export type IntegrationSettingsType = z.infer<typeof IntegrationSettingsSchema>;

export const getIntegrationSettings = async (signal: AbortSignal) => {
  const response = await apiClient.get("/settings/integration", { signal });
  return IntegrationSettingsSchema.parse(response.data);
};

const updateIntegrationSettings = async (data: IntegrationSettingsType) => {
  const response = await apiClient.put("/settings/integration", data);
  return response.data;
};

export const useUpdateIntegrationSettings = () =>
  useMutation({
    mutationKey: ["general_settings"],
    mutationFn: updateIntegrationSettings,
  });

const GlobalSettingsSchema = z.object({
  allowEditingOfUnprocessedExpenses: z.boolean(),
});

export type GlobalSettingsType = z.infer<typeof GlobalSettingsSchema>;

export const getGlobalSettings = async (signal: AbortSignal) => {
  const response = await apiClient.get("/settings/global", { signal });
  return GlobalSettingsSchema.parse(response.data);
};

export const useGlobalSettings = () => {
  const loadedData = useRouteLoaderData("main") as LoadedData;
  return loadedData?.settings;
};

export type LoadedData = {
  settings: GlobalSettingsType;
  companies: MyCompanyType[];
};
