import { apiClient } from "@/services/http.ts";
import { useMutation, useQuery } from "@tanstack/react-query";
import { z } from "zod";
import { format } from "date-fns";
import qs from "qs";
import { useCurrentCompany } from "@/services/companies.ts";
import { AddressSchema, pageSchema, ParamsType } from "@/services/common.ts";
import { generateFilterableRequest } from "@/libs/generate-filterable-request.ts";
import { queryClient } from "@/query-client.ts";
import { FlowSchema } from "@/services/flows.ts";

const GetEmployeesResponseSchema = z.object({
  id: z.string(),
  externalId: z.string(),
  name: z.string(),
  email: z.string(),
  nif: z.string(),
  active: z.boolean(),
  office: z.object({
    id: z.string(),
    name: z.string(),
  }),
});

const GetEmployeeResponseSchema = GetEmployeesResponseSchema.merge(
  z.object({
    licensePlate: z.string(),
    vehicleOwner: z.string(),
    startDate: z.preprocess(
      (val) => (val && typeof val === "string" ? new Date(val) : undefined),
      z.date(),
    ),
    endDate: z.preprocess(
      (val) => (val && typeof val === "string" ? new Date(val) : undefined),
      z.date().optional(),
    ),
    address: AddressSchema,
    secondaryEmail: z.string().nullable(),
    sendToSecondaryEmail: z.boolean(),
  }),
);

export type GetEmployeesResponseType = z.infer<
  typeof GetEmployeesResponseSchema
>;

export type GetEmployeeResponseType = z.infer<typeof GetEmployeeResponseSchema>;

const getEmployees = async (
  { paginationModel, sortModel, filterModel }: ParamsType,
  signal: AbortSignal,
) => {
  const response = await apiClient.post(`/employees/search`, filterModel, {
    params: {
      page: paginationModel.page,
      size: paginationModel.pageSize,
      sort: sortModel?.map(
        (sort) => `${sort.field.replace(".id", ".name")},${sort.sort}`,
      ),
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
    signal,
  });
  return pageSchema(GetEmployeesResponseSchema).parse(response.data);
};

export const useEmployees = (params: ParamsType) => {
  const [company] = useCurrentCompany();
  return useQuery({
    queryKey: ["employees", params],
    queryFn: ({ signal }) => getEmployees(params, signal),
    enabled: Boolean(company),
  });
};

export const getEmployee = async (id: string, signal: AbortSignal) => {
  const response = await apiClient.get(`/employees/${id}`, { signal });
  return GetEmployeeResponseSchema.parse(response.data);
};

export const EmployeeMutationFormSchema = z.object({
  externalId: z.string().nullable(),
  name: z.string().min(1),
  email: z.string().email(),
  nif: z.string(),
  active: z.boolean(),
  manager: z.string().min(1),
  office: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
  licensePlate: z.string(),
  vehicleOwner: z.string(),
  startDate: z.date().nullable(),
  endDate: z.date().nullable(),
  address: AddressSchema,
  totalKms: z.number().optional(),
  userExtraOnFirstMonth: z.boolean().optional().nullable(),
  secondaryEmail: z.string().nullable(),
  sendToSecondaryEmail: z.boolean(),
});

export type EmployeeMutationFormType = z.infer<
  typeof EmployeeMutationFormSchema
>;

export const EmployeeMutationRequestSchema = z.object({
  externalId: z.string().nullable(),
  name: z.string().min(1),
  email: z.string().email(),
  nif: z.string(),
  active: z.boolean().optional(),
  office: z.preprocess(
    (val) =>
      val !== null && typeof val === "object" && "id" in val
        ? val.id
        : undefined,
    z.string().uuid(),
  ),
  licensePlate: z.string(),
  vehicleOwner: z.string(),
  startDate: z.preprocess(
    (val) => (val instanceof Date ? format(val, "yyyy-MM-dd") : undefined),
    z.string().date(),
  ),
  endDate: z.preprocess(
    (val) => (val instanceof Date ? format(val, "yyyy-MM-dd") : undefined),
    z.string().date().nullable().optional(),
  ),
  address: AddressSchema,
  totalKms: z.preprocess(
    (val) => (val ? Number(val) : undefined),
    z.number().optional(),
  ),
  userExtraOnFirstMonth: z.boolean().optional().nullable(),
  secondaryEmail: z.string().nullable().optional(),
  sendToSecondaryEmail: z.boolean(),
});

export type EmployeeMutationType = z.infer<
  typeof EmployeeMutationRequestSchema
>;

const createEmployee = async (data: EmployeeMutationType) => {
  const response = await apiClient.post<string>("/employees", data);
  return response.data;
};

export const useCreateEmployee = () =>
  useMutation({
    mutationKey: ["employee"],
    mutationFn: createEmployee,
  });

const updateEmployee = async (
  employeeId: string,
  data: EmployeeMutationType,
) => {
  const response = await apiClient.put(`/employees/${employeeId}`, data);
  return response.data;
};

export const useUpdateEmployee = (employeeId: string) =>
  useMutation({
    mutationKey: ["employee", employeeId],
    mutationFn: (variables: EmployeeMutationType) =>
      updateEmployee(employeeId, variables),
  });

const getEmployeesIndex = async (search: string, signal: AbortSignal) => {
  const response = await apiClient.get("/employees/index", {
    params: {
      search,
      sort: ["name,asc"],
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
    signal,
  });
  return response.data;
};

export const useEmployeesIndex = (search: string) =>
  useQuery({
    queryKey: ["employees_index", search],
    queryFn: ({ signal }) => getEmployeesIndex(search, signal),
  });

const EmployeeKmsResponseSchema = z.object({
  id: z.string().uuid(),
  startDate: z.preprocess(
    (val) => (typeof val === "string" ? new Date(val) : undefined),
    z.date(),
  ),
  endDate: z.preprocess(
    (val) => (typeof val === "string" ? new Date(val) : null),
    z.date().nullable(),
  ),
  processed: z.boolean(),
  currentlyActive: z.boolean(),
  totalKms: z.number(),
});

export type EmployeeKmsResponseType = z.infer<typeof EmployeeKmsResponseSchema>;

const getEmployeeKms = async (
  employeeId: string,
  { filterModel, ...rest }: ParamsType,
  signal: AbortSignal,
) => {
  const response = await apiClient.post(
    `/employees/${employeeId}/kms`,
    filterModel,
    {
      ...generateFilterableRequest(rest),
      signal,
    },
  );
  return pageSchema(EmployeeKmsResponseSchema).parse(response.data);
};

export const useEmployeeKms = (employeeId: string, params: ParamsType) =>
  useQuery({
    queryKey: ["employee-kms", employeeId, params],
    queryFn: ({ signal }) => getEmployeeKms(employeeId, params, signal),
  });

const scheduleKmChange = async (employeeId: string, data: ScheduleKmType) => {
  const response = await apiClient.post(
    `/employees/${employeeId}/kms/create`,
    data,
  );
  return response.data;
};

export const useScheduleKm = (employeeId: string) =>
  useMutation({
    mutationKey: ["employee-kms", employeeId],
    mutationFn: (data: ScheduleKmType) => scheduleKmChange(employeeId, data),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ["employee-kms", employeeId],
        exact: false,
      });
    },
  });

export const ScheduleKmSchema = z.object({
  startDate: z.date(),
  totalKms: z.preprocess(
    (arg) => (typeof arg === "string" ? Number(arg) : undefined),
    z.number(),
  ),
});

export type ScheduleKmForm = {
  startDate: Date | null;
  totalKms: number | null;
};

export type ScheduleKmType = z.infer<typeof ScheduleKmSchema>;

const deleteKm = async (employeeId: string, kmId: string) => {
  const response = await apiClient.delete(
    `/employees/${employeeId}/kms/${kmId}`,
  );
  return response.data;
};

const ExpenseKmSchema = z.object({
  id: z.string().uuid(),
  startDate: z.preprocess(
    (arg) => (typeof arg === "string" ? new Date(arg) : undefined),
    z.date(),
  ),
  totalKms: z.number(),
});

export type ExpenseKmType = z.infer<typeof ExpenseKmSchema>;

export const useDeleteKm = (employeeId: string) =>
  useMutation({
    mutationKey: ["employee-km", employeeId],
    mutationFn: (kmId: string) => deleteKm(employeeId, kmId),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ["employee-kms", employeeId],
        exact: false,
      });
    },
  });

export const getEmployeeKm = async (
  employeeId: string,
  kmId: string,
  signal: AbortSignal,
) => {
  const response = await apiClient.get(`/employees/${employeeId}/kms/${kmId}`, {
    signal,
  });
  return ExpenseKmSchema.parse(response.data);
};

const getEmployeeFlows = async (
  signal: AbortSignal,
  employeeId: string,
  { filterModel, ...rest }: ParamsType,
) => {
  const response = await apiClient.post(
    `/employees/${employeeId}/flows`,
    filterModel,
    {
      ...generateFilterableRequest(rest),
      signal,
    },
  );
  return pageSchema(FlowSchema.omit({ logs: true })).parse(response.data);
};

export const useEmployeeFlows = (employeeId: string, params: ParamsType) =>
  useQuery({
    queryKey: ["employee-flows", employeeId],
    queryFn: ({ signal }) => getEmployeeFlows(signal, employeeId, params),
  });
