import { pageSchema, ParamsType } from "@/services/common.ts";
import { apiClient } from "@/services/http.ts";
import { generateFilterableRequest } from "@/libs/generate-filterable-request.ts";
import { useMutation, useQuery } from "@tanstack/react-query";
import { z } from "zod";

const GetUsersResponse = z.object({
  id: z.string().uuid(),
  name: z.string(),
  email: z.string(),
  tenants: z.array(
    z.object({
      id: z.string().uuid(),
      name: z.string(),
      base64Logo: z.string().nullable(),
    }),
  ),
});

const getUsers = async (
  { paginationModel, sortModel, filterModel }: ParamsType,
  signal: AbortSignal,
) => {
  const response = await apiClient.post(`/users/search`, filterModel, {
    ...generateFilterableRequest({
      paginationModel,
      sortModel,
    }),
    signal,
  });
  return pageSchema(GetUsersResponse).parse(response.data);
};

export const useUsers = (params: ParamsType) =>
  useQuery({
    queryKey: ["users", params],
    queryFn: ({ signal }) => getUsers(params, signal),
  });

export const GetUserResponse = z.object({
  id: z.string().uuid(),
  name: z.string(),
  email: z.string(),
  tenants: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
    }),
  ),
});

export type GetUserResponseType = z.infer<typeof GetUserResponse>;

export const getUser = async (id: string, signal: AbortSignal) => {
  const response = await apiClient.get(`/users/${id}`, { signal });
  return GetUserResponse.parse(response.data);
};

export const UpdateUserRequestSchema = z.object({
  tenants: z.preprocess(
    (val) =>
      val && Array.isArray(val) ? val.map(({ id }) => ({ id })) : undefined,
    z.array(z.object({ id: z.string().uuid() })),
  ),
});

export type UpdateUserRequestType = z.infer<typeof UpdateUserRequestSchema>;

const updateUser = async (id: string, data: UpdateUserRequestType) => {
  const response = await apiClient.put(`/users/${id}`, data);
  return response.data;
};

export const useUpdateUser = (id: string) => {
  return useMutation({
    mutationKey: ["users"],
    mutationFn: (variables: UpdateUserRequestType) => updateUser(id, variables),
  });
};
