import { useQuery } from "@tanstack/react-query";
import { apiClient } from "@/services/http.ts";
import { z } from "zod";

const getTenantsIndex = async (search: string, signal: AbortSignal) => {
  const response = await apiClient.get("/tenants/index", {
    params: { search },
    signal,
  });
  return response.data;
};

export const useTenantsIndex = (search: string) => {
  return useQuery({
    queryKey: ["tenants", { search }],
    queryFn: ({ signal }) => getTenantsIndex(search, signal),
  });
};

export const tenantsZodSchema = z.array(
  z.object({
    id: z.string().uuid(),
    name: z.string(),
    base64Logo: z.string().nullable(),
  }),
);
