export enum Permission {
  UpdateGeneralSettings = "settings:write:general",
  UpdateCompanies = "settings:write:companies",
  UpdateClients = "settings:write:clients",
  UpdateUsers = "settings:write:users",
  UpdateHolidays = "settings:write:holidays",
  UpdateIntegrationSettings = "settings:write:integration",
  RunDocumentSendingProcess = "flows:run:document-sending",
  RunMovementGenerationProcess = "flows:run:movement-generation",
  ReadFlowHistory = "flows:read:executions",
  ReadEmployees = "employees:read",
  WriteEmployees = "employees:write",
}
